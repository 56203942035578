import { Box, css, styled } from '@mui/material'

import { Odometer, Typography } from '@lib/ui'

export const StyledOdometer = styled(Odometer)`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;

  ${({
    theme: {
      breakpoints: {
        values: { xs, sm, md, lg }
      }
    }
  }) => {
    return css`
      @media (min-width: ${xs}px) {
        font-size: 14px;
        line-height: 18px;
      }

      @media (min-width: ${sm}px) {
        font-size: 20px;
        line-height: 24px;
      }

      @media (min-width: ${md}px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media (min-width: ${lg}px) {
        font-size: 28px;
        line-height: 36px;
      }
    `
  }}
`

export const StyledTypography = styled(Typography)`
  color: var(--Neutral1) !important;
  text-shadow:
    -1px -1px 0 var(--Primary),
    1px -1px 0 var(--Primary),
    -1px 1px 0 var(--Primary),
    1px 1px 0 var(--Primary),
    0 -1px 0 var(--Primary),
    0 1px 0 var(--Primary),
    -1px 0 0 var(--Primary),
    1px 0 0 var(--Primary) !important;
`

export const StyledHomeCarouselJackpotBadge = styled(Box)`
  --f: 15px; /* Control the folded part */
  --r: 15px; /* Control the ribbon shape */

  color: var(--Neutral1) !important;
  padding: 6px 24px 6px 12px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background: radial-gradient(
      161.58% 147.31% at 19.54% -14.58%,
      rgba(0, 0, 0, 0.23) 0%,
      rgba(0, 0, 0, 0) 86.18%
    ),
    radial-gradient(
      130.11% 135.29% at 104.98% 100.23%,
      rgba(255, 255, 255, 0.24) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      271deg,
      var(--SecondaryVariant) -31.44%,
      var(--Primary) 91.35%
    );
  border-bottom: var(--f) solid #0005;
  border-right: var(--r);
  clip-path: polygon(
    0 0,
    0 calc(100% - var(--f)),
    var(--f) 100%,
    var(--f) calc(100% - var(--f)),
    100% calc(100% - var(--f)),
    calc(100% - var(--r)) calc(50% - var(--f) / 2),
    100% 0
  );
`
