import { isNil } from 'lodash-es'

import { InferGetServerSidePropsType } from 'next'

import { useCallback, useEffect, useMemo } from 'react'

import { useRouter } from 'next/router'

import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query'

import {
  Collapsible,
  CompetitionTilesGrid,
  Container,
  DrawTodayCompetitionTilesGrid,
  Head,
  TrustReviewsContainer,
  InstaWinCompetitionTilesGrid,
  Title
} from '@lib/ui'

import OurStories from '@features/homePage/OurStories'
import SocialProofNumbers from '@features/homePage/SocialProofNumbers'
import HowItWorks from '@features/homePage/HowItWorks'
import HomeCarousel from '@features/homePage/HomeCarousel'

import { getLocalAssetFromCDN, prepareSocialProofNumText } from '@helpers'

import { getHomePageContent, getSocialStatsContent } from '@api/WebsiteContent'

import { QueryClientKeysEnum } from '@enums'

import {
  type CompetitionsGetCompetitionsData,
  CompetitionsService,
  CompetitionStatus,
  TestimonialsService
} from '@elitecompetitions/client-api'

import { Stack } from '@mui/material'

import { splitCompetitions } from '@helpers/competitions/splitCompetitions'

import {
  PageTypeEnum,
  sendAppsFlyerListViewChannelMessage
} from '@utils/channels/appsFlyerChannel'

const competitionsParams: CompetitionsGetCompetitionsData = {
  filterStatus: CompetitionStatus.ACTIVE,
  filterIsInvisible: false,
  take: -1
}

const HomePage = (
  props: InferGetServerSidePropsType<typeof getServerSideProps>
) => {
  const { websiteContents, socialStatNumbers, homeContent } = props

  const { title, keywords, description } = websiteContents || {}

  const router = useRouter()

  const prefetch = useCallback(async () => {
    await router.prefetch('/competitions/[slug]')
  }, [])

  const { data: testimonials = [] } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_TESTIMONIALS],
    queryFn: TestimonialsService.getAllTestimonials
  })

  const {
    data: activeCompetitions = splitCompetitions([]),
    isFetched = false
  } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_COMPETITIONS, competitionsParams],
    queryFn: () => CompetitionsService.getCompetitions(competitionsParams),
    select: ({ data }) => splitCompetitions(data)
  })

  const canSortByHighValue = useMemo(() => {
    const { carousel, drawnToday } = activeCompetitions

    return !carousel.overRideCarousel && drawnToday.length > 0
  }, [activeCompetitions])

  useEffect(() => {
    if (isFetched) {
      ;(async () => {
        await sendAppsFlyerListViewChannelMessage({
          pageType: PageTypeEnum.HOME_PAGE,
          competitions: activeCompetitions.all
        })
      })()
    }
  }, [isFetched])

  useEffect(() => {
    window.addEventListener('load', prefetch)

    return () => {
      window.removeEventListener('load', prefetch)
    }
  }, [])

  return (
    <>
      <Head
        title={title || 'Elite Competitions - Win unbelievable big prizes!'}
        description={
          description ||
          'With Elite competitions, buy multiple tickets to win big prizes. For a little price, you get to win big prizes like cars, houses, accessories, cash and more! Every competition has a guaranteed winner'
        }
        keywords={keywords || null}
        url="/"
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "image": "${getLocalAssetFromCDN('/assets/logos/og-logo.png')}",
                "url": "https://elitecompetitions.co.uk/",
                "sameAs": [
                  "https://www.facebook.com/elitecompltd/",
                  "https://twitter.com/elitecompltd",
                  "https://www.instagram.com/elitecompetitionsltd",
                  "https://www.youtube.com/channel/UCdqJoP6_7pXuid2xr-Hz3Yg",
                  "https://www.tiktok.com/@elite_competitions"
                ],
                "logo": "${getLocalAssetFromCDN(
                  '/assets/logos/main-logo.svg'
                )}",
                "name": "Elite Competitions",
                "description": "Elite Competitions give you the chance to win cars, cash, tech, and homes. Scores of UK winners each month. Thousands of reviews.",
                "email": "info@elitecompetitions.co.uk",
                "telephone": "01253 836633",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Unit 19, 20 & Unit 21, Kincraig Business Park, Kincraig Road",
                  "addressLocality": "Blackpool",
                  "addressCountry": "UK",
                  "addressRegion": "Lancashire",
                  "postalCode": "FY2 0PJ"
                },
                "vatID": "FR12345678901"
              }
            `
          }}
        />
      </Head>

      <section>
        <HomeCarousel
          drawnTodayCompetitions={activeCompetitions.carousel.competitions}
          sortByHighValue={canSortByHighValue}
        />
      </section>

      <Container>
        <Stack
          spacing={{
            sm: 10,
            xs: 5
          }}
          marginBottom={{
            sm: 10,
            xs: 5
          }}
        >
          <Stack
            spacing={{
              sm: 6,
              xs: 3
            }}
          >
            <section>
              <SocialProofNumbers socialStatNumbers={socialStatNumbers} />
            </section>
          </Stack>

          <section>
            <HowItWorks />
          </section>

          <Stack
            spacing={{
              sm: 6,
              xs: 3
            }}
          >
            {activeCompetitions.drawnToday.length > 0 && (
              <DrawTodayCompetitionTilesGrid
                heading="Online Competitions Drawn Soon"
                drawnTodayCompetitions={activeCompetitions.drawnToday}
              />
            )}

            {activeCompetitions.instaWins.length > 0 && (
              <InstaWinCompetitionTilesGrid
                instaWinCompetitions={activeCompetitions.instaWins}
              />
            )}

            {activeCompetitions.featuredAndFree.length > 0 && (
              <CompetitionTilesGrid
                heading="Featured Competitions"
                competitions={activeCompetitions.featuredAndFree}
              />
            )}

            {activeCompetitions.other.length > 0 && (
              <CompetitionTilesGrid
                heading="More Competitions"
                competitions={activeCompetitions.other}
              />
            )}

            {activeCompetitions.saturdaySesh.length > 0 && (
              <CompetitionTilesGrid
                heading="Winner Wednesdays"
                competitions={activeCompetitions.saturdaySesh}
              />
            )}

            {activeCompetitions.soldOut.length > 0 && (
              <CompetitionTilesGrid
                heading="Sold Out Competitions"
                competitions={activeCompetitions.soldOut}
              />
            )}
          </Stack>

          <Stack
            spacing={{
              sm: 8,
              xs: 4
            }}
          >
            {testimonials.length > 0 && (
              <section>
                <OurStories values={testimonials} />
              </section>
            )}

            <TrustReviewsContainer />
          </Stack>

          <Stack direction="column" width="100%" gap="16px">
            <Title variant="h2">Online Competitions</Title>

            <Collapsible content={homeContent} />
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

export const getServerSideProps = async () => {
  const queryClient = new QueryClient()

  try {
    const [socialStatsData, homePageData] = await Promise.all([
      queryClient.ensureQueryData({
        queryKey: [QueryClientKeysEnum.GET_SOCIAL_STATS_CONTENT],
        queryFn: getSocialStatsContent
      }),
      queryClient.ensureQueryData({
        queryKey: [QueryClientKeysEnum.GET_HOMEPAGE_CONTENT],
        queryFn: getHomePageContent
      }),
      queryClient.prefetchQuery({
        queryKey: [QueryClientKeysEnum.GET_TESTIMONIALS],
        queryFn: TestimonialsService.getAllTestimonials
      }),
      queryClient.prefetchQuery({
        queryKey: [QueryClientKeysEnum.GET_COMPETITIONS, competitionsParams],
        queryFn: () => CompetitionsService.getCompetitions(competitionsParams)
      })
    ])

    const socialStatsResult = (socialStatsData || {}).content || {}
    const socialStatNumbers = {
      followers: isNil(socialStatsResult.followers)
        ? '210,000+'
        : prepareSocialProofNumText(socialStatsResult.followers),
      prizesWon: isNil(socialStatsResult.prizesWon)
        ? '£50M+'
        : `£${prepareSocialProofNumText(socialStatsResult.prizesWon)}`,
      winners: isNil(socialStatsResult.winners)
        ? '7,000+'
        : prepareSocialProofNumText(socialStatsResult.winners, false),
      reviews: socialStatsResult.reviews
    }

    return {
      props: {
        websiteContents: (homePageData || {}).content || {},
        socialStatNumbers,
        homeContent: socialStatsResult.homeContent || '',
        dehydratedState: dehydrate(queryClient)
      }
    }
  } catch (error) {
    console.error('Error in getinital props home', error)
  }

  return {
    props: {
      websiteContents: {}
    }
  }
}

export default HomePage
