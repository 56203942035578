import { css, styled } from '@mui/material'

export const StyledHomeCarouselWrapper = styled('div')`
  padding: 40px 0 0;

  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const StyledIdleImage = styled('div')`
  opacity: 0;
  padding: 80px 360px;

  ${({
    theme: {
      breakpoints: {
        values: { sm, xl }
      }
    }
  }) => {
    return css`
      @media screen and (max-width: ${xl}px) {
        padding: 40px;
      }

      @media screen and (max-width: ${sm}px) {
        padding: 20px;
      }
    `
  }}
`
