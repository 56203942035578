import { Typography } from '@mui/material'

import { StyledGrid2, StyledGrid2Item } from './SocialProofNumbers.styled'

const socialProofs = [
  {
    icon: '🏆',
    defaultText: 'prizes won',
    key: 'prizesWon',
    paddingLeft: '5px'
  },
  {
    icon: '🏅',
    defaultText: 'total winners',
    key: 'winners',
    paddingLeft: '10px'
  },
  {
    icon: '💫',
    defaultText: 'social media followers',
    key: 'followers',
    paddingLeft: '0px'
  }
]

const SocialProofNumbers = props => {
  const { socialStatNumbers = {} } = props

  return (
    <StyledGrid2 container>
      {socialProofs.map((socialProof, idx) => {
        const { key, icon, defaultText, paddingLeft } = socialProof

        return (
          <StyledGrid2Item key={idx}>
            <Typography
              fontFamily="Poppins"
              fontWeight={{
                lg: 700,
                md: 700,
                sm: 600,
                xs: 600
              }}
              fontSize={{
                lg: '40px',
                md: '32px',
                sm: '26px',
                xs: '20px'
              }}
              lineHeight={{
                lg: '48px',
                md: '40px',
                sm: '32px',
                xs: '26px'
              }}
            >
              {`${icon} ${socialStatNumbers[key]}`}
            </Typography>

            <Typography
              sx={{
                paddingLeft
              }}
              fontFamily="Poppins"
              fontWeight={400}
              fontSize={{
                lg: '14px',
                md: '14px',
                sm: '12px',
                xs: '10px'
              }}
              lineHeight={{
                lg: '22px',
                md: '18px',
                sm: '16px',
                xs: '14px'
              }}
            >
              {defaultText}
            </Typography>
          </StyledGrid2Item>
        )
      })}
    </StyledGrid2>
  )
}

export default SocialProofNumbers
