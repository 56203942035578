import { styled } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

export const StyledGrid2 = styled(Grid2)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 8px;
  background: var(--SecondaryVariant);
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.22) inset,
    0 -1px 1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(34px);
  gap: 5px;

  @media (min-width: 600px) {
    gap: 30px;
  }

  @media (min-width: 900px) {
    gap: 50px;
  }

  @media (min-width: 1240px) {
    gap: 80px;
  }
`

export const StyledGrid2Item = styled(Grid2)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--Neutral1);
`
