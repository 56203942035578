import { Typography } from '@mui/material'

import { Badge } from '@lib/ui'

import { type IHomeCarouselFeatureBadgeProps } from './HomeCarouselFeatureBadge.types'

const HomeCarouselFeatureBadge = (props: IHomeCarouselFeatureBadgeProps) => {
  const { badgeContent, variant } = props

  return (
    <Badge variant={variant}>
      <Typography
        fontFamily="Poppins"
        fontWeight={700}
        lineHeight={{
          lg: '36px',
          md: '30px',
          sm: '24px',
          xs: '18px'
        }}
        fontSize={{
          lg: '28px',
          md: '24px',
          sm: '20px',
          xs: '14px'
        }}
      >
        {badgeContent}
      </Typography>
    </Badge>
  )
}

export default HomeCarouselFeatureBadge
