import { chunk } from 'lodash-es'

import { useCallback } from 'react'

import { Carousel } from 'react-responsive-carousel'

import { Stack, useMediaQuery, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

import { Title, Typography } from '@lib/ui'

import { StyledCard, StyledCardContent, StyledGrid2 } from './HowItWorks.styled'

const items = [
  {
    title: '🎯 Make an account',
    content:
      'Set up your account, it’s super easy and we only need a few details.'
  },
  {
    title: '🏆 Pick a competition',
    content:
      'Choose from any of our competitions; ranging from cash, luxury and everyday cars, tech, once in a lifetime holiday’s and more.'
  },
  {
    title: '🎟 Select your tickets',
    content:
      'Choose how many tickets you would like by using our "lucky dip" selector.'
  },
  {
    title: '🏅 Are you a winner?',
    content:
      "If you've entered an InstaWin competition you will find out instantly, if not, sit back and wait for our live draw to take place; you can tune in via Facebook and YouTube."
  },
  {
    title: '❤️ Timeframes for winners',
    content:
      'Winners will be contacted within 30 minutes of live draw; InstaWinners within 24 hours.'
  },
  {
    title: '🎁 Receiving your prize',
    content:
      'If you’re the lucky winner, we will deliver your prize absolutely free of charge; all we ask for is a picture of you with your prize.'
  }
]

const HowItWorks = () => {
  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  const renderCarouselCards = useCallback(() => {
    return chunk(items, isXs ? 1 : 3).map((values, idx) => {
      return (
        <StyledGrid2 container spacing={2} key={`how-it-works-row-${idx}`}>
          {values.map((value, ydx) => {
            return (
              <Grid2 xs={12} sm={4} key={`how-it-works-card-${idx}-${ydx}`}>
                <StyledCard>
                  <StyledCardContent>
                    <Stack spacing={2}>
                      <Typography fontVariant="heading-3" weight="semibold">
                        {value.title}
                      </Typography>

                      <Typography fontVariant="body-1" weight="regular">
                        {value.content}
                      </Typography>
                    </Stack>
                  </StyledCardContent>
                </StyledCard>
              </Grid2>
            )
          })}
        </StyledGrid2>
      )
    })
  }, [isXs])

  return (
    <Stack spacing={2}>
      <Title>How it works</Title>

      <Carousel
        autoPlay={true}
        showArrows={true}
        emulateTouch={true}
        infiniteLoop={true}
        interval={5000}
        showThumbs={false}
        stopOnHover={true}
        showIndicators={false}
        showStatus={false}
      >
        {renderCarouselCards()}
      </Carousel>
    </Stack>
  )
}

export default HowItWorks
