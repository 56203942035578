import Image from 'next/image'

import { chunk } from 'lodash-es'

import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { Carousel } from 'react-responsive-carousel'

import {
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  CardMedia
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

import { replaceImagePathTemplate } from '@helpers/competitions/replaceImagePathTemplate'

import { Title } from '@lib/ui'

import { StyledCard, StyledCardContent, StyledGrid2 } from './OurStories.styled'
import { type IOurStoriesProps } from './OurStories.types'

const OurStories = (props: IOurStoriesProps) => {
  const { values = [] } = props

  const router = useRouter()

  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  const renderCarouselPics = useCallback(() => {
    return chunk(values, isXs ? 1 : 2).map((values, idx) => {
      return (
        <StyledGrid2 container spacing={2} key={`our-stories-rows-${idx}`}>
          {values.map((value, ydx) => {
            const {
              winnerName,
              prizeName,
              winnerProfileCDNImage,
              winnerResponse
            } = value

            return (
              <Grid2 xs={12} sm={6} key={`our-stories-card-${idx}-${ydx}`}>
                <StyledCard>
                  <CardMedia>
                    <Image
                      loading="lazy"
                      alt={`${winnerName}-${prizeName}`}
                      width={600}
                      height={350}
                      src={replaceImagePathTemplate(
                        winnerProfileCDNImage,
                        'medium',
                        'webp',
                        2
                      )}
                    />
                  </CardMedia>

                  <StyledCardContent>
                    <Stack spacing={1}>
                      {winnerResponse && (
                        <Typography
                          fontSize="14px"
                          fontWeight={400}
                          lineHeight="18px"
                        >
                          {`"${winnerResponse}"`}
                        </Typography>
                      )}

                      {winnerName && (
                        <Typography
                          fontSize="16px"
                          fontWeight={600}
                          lineHeight="22px"
                        >
                          {winnerName}
                        </Typography>
                      )}

                      {prizeName && (
                        <Typography
                          fontSize="16px"
                          fontWeight={600}
                          lineHeight="22px"
                        >
                          The winner of {prizeName}
                        </Typography>
                      )}
                    </Stack>
                  </StyledCardContent>
                </StyledCard>
              </Grid2>
            )
          })}
        </StyledGrid2>
      )
    })
  }, [values, isXs])

  return (
    <Stack spacing={2}>
      <Title>Top all-time raffle winners</Title>

      <Carousel
        autoPlay={true}
        showArrows={true}
        emulateTouch={true}
        infiniteLoop={true}
        interval={5000}
        showThumbs={false}
        stopOnHover={true}
        showIndicators={true}
        showStatus={false}
        onClickItem={() => {
          router.push('/winners-podium')
        }}
      >
        {renderCarouselPics()}
      </Carousel>
    </Stack>
  )
}

export default OurStories
