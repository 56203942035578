import { Card, CardContent, styled } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

export const StyledGrid2 = styled(Grid2)`
  height: 100%;
`

export const StyledCard = styled(Card)`
  border-radius: 8px;
  background: var(--Neutral8);
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.22) inset,
    0 -1px 1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(34px);
  width: 100%;
  height: 100%;
  padding: 16px;
`

export const StyledCardContent = styled(CardContent)`
  color: var(--Neutral1);
  display: flex;
  justify-content: flex-start;
  text-align: start;
`
