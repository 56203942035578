import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

import { FC, ReactNode } from 'react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules'
import { Swiper } from 'swiper/react'

export interface MainCarouselProps {
  children: ReactNode
}

const MainCarousel: FC<MainCarouselProps> = ({ children }) => {
  return (
    <Swiper
      effect="coverflow"
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={1.3}
      spaceBetween={0}
      loop={true}
      allowSlideNext={true}
      preventClicks={true}
      coverflowEffect={{
        rotate: 60,
        stretch: 0,
        depth: 200,
        modifier: 1,
        slideShadows: true
      }}
      pagination={{
        clickable: true,
        dynamicBullets: true
      }}
      breakpoints={{
        640: {
          spaceBetween: 20
        },
        768: {
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 1.3,
          spaceBetween: 30
        },
        1200: {
          slidesPerView: 1.5,
          spaceBetween: 80
        }
      }}
      modules={[Navigation, Pagination, EffectCoverflow]}
      className="swiper-carousel"
      style={{ paddingBottom: '20px' }}
    >
      {children}
    </Swiper>
  )
}

export default MainCarousel
