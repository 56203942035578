import { useEffect, useMemo, useState } from 'react'

import { orderBy } from 'lodash-es'

import { SwiperSlide } from 'swiper/react'

import MainCarousel from '@lib/ui/MainCarousel'

import HomeCarouselItem from '../HomeCarouselItem'

import {
  StyledHomeCarouselWrapper,
  StyledIdleImage
} from './HomeCarousel.styled'
import { type IHomeCarouselProps } from './HomeCarousel.types'

const HomeCarousel = (props: IHomeCarouselProps) => {
  const { drawnTodayCompetitions = [], sortByHighValue = false } = props
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const competitionLists = useMemo(() => {
    let lists = [...drawnTodayCompetitions]

    if (sortByHighValue) {
      lists = orderBy(
        drawnTodayCompetitions,
        [competition => competition.isSoldOut],
        ['asc']
      )
    }

    return lists
  }, [drawnTodayCompetitions, sortByHighValue])

  if (!competitionLists?.length) {
    return null
  }

  return (
    <StyledHomeCarouselWrapper>
      {!isMounted && (
        <StyledIdleImage>
          <img alt="" role="presentation" width={1920} height={1080} />
        </StyledIdleImage>
      )}
      {isMounted && (
        <MainCarousel>
          {competitionLists.map(competition => {
            return (
              <SwiperSlide key={`home-carousel-item-${competition.id}`}>
                {({ isActive, isNext, isPrev }) => {
                  return (
                    <HomeCarouselItem
                      isActive={isActive}
                      isNext={isNext}
                      isPrev={isPrev}
                      competition={competition}
                    />
                  )
                }}
              </SwiperSlide>
            )
          })}
        </MainCarousel>
      )}
    </StyledHomeCarouselWrapper>
  )
}

export default HomeCarousel
