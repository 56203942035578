import { useMemo } from 'react'

import { isDrawToday } from '@helpers/competitions/splitCompetitions'

import HomeCarouselFeatureBadge from '../HomeCarouselFeatureBadge'

import { type IHomeCarouselFeatureBadgesProps } from './HomeCarouselFeatureBadges.types'

const HomeCarouselFeatureBadges = (props: IHomeCarouselFeatureBadgesProps) => {
  const { competition } = props

  const { isAppOnly = false, isFree = false } = competition

  const isEndingSoon = useMemo(() => {
    return isDrawToday(competition)
  }, [competition])

  if (isAppOnly && isFree && isEndingSoon) {
    return (
      <HomeCarouselFeatureBadge
        variant="appExclusive"
        badgeContent="App exclusive"
      />
    )
  }

  if (isAppOnly && isFree) {
    return (
      <HomeCarouselFeatureBadge
        variant="appExclusive"
        badgeContent="App exclusive"
      />
    )
  }

  if (isFree && isEndingSoon) {
    return (
      <HomeCarouselFeatureBadge
        variant="freeToEnter"
        badgeContent="Free to enter"
      />
    )
  }

  if (isEndingSoon) {
    return (
      <HomeCarouselFeatureBadge
        variant="endingSoon"
        badgeContent="Ending soon"
      />
    )
  }

  if (isFree) {
    return (
      <HomeCarouselFeatureBadge
        variant="freeToEnter"
        badgeContent="Free to enter"
      />
    )
  }

  if (isAppOnly) {
    return (
      <HomeCarouselFeatureBadge
        variant="appExclusive"
        badgeContent="App exclusive"
      />
    )
  }

  return null
}

export default HomeCarouselFeatureBadges
