import { Box, css, Stack, styled } from '@mui/material'

export const StyledAkaButton = styled('span')`
  margin: 16px 12px;
  width: calc(100% - 24px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  user-select: none;
  color: var(--Neutral1);
  background-color: var(--Primary);
  max-height: 40px;
  border-radius: 8px;
  padding: 10px 24px;

  @media (min-width: 600px) {
    display: none;
  }
`

export const StyledBadgeContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'isJackpot'
})<{
  isJackpot?: boolean
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
  width: 100%;
  padding-right: 8px;
  bottom: 70px;
  left: 0;
  padding-bottom: 8px;

  @media (min-width: 600px) {
    padding-bottom: 16px;
    padding-right: 16px;
    bottom: 0;
    gap: 8px;
  }

  ${({ isJackpot = false }) => {
    if (isJackpot) {
      return css`
        top: 1% !important;
        bottom: unset !important;
        left: -15px !important;
      `
    }
  }}
`

export const StyledBadgeStack = styled(Stack)`
  gap: 4px;

  @media (min-width: 600px) {
    gap: 8px;
  }
`
