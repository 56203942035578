import {
  StyledHomeCarouselJackpotBadge,
  StyledOdometer,
  StyledTypography
} from './HomeCarouselJackpotBadge.styled'
import { type IHomeCarouselJackpotBadgeProps } from './HomeCarouselJackpotBadge.types'

const HomeCarouselJackpotBadge = (props: IHomeCarouselJackpotBadgeProps) => {
  const { jackpotAmount = 0, isLoadingJackpot = false } = props

  return (
    <StyledHomeCarouselJackpotBadge>
      <StyledTypography
        lineHeight={{
          lg: '36px',
          md: '30px',
          sm: '24px',
          xs: '18px'
        }}
        fontSize={{
          lg: '28px',
          md: '24px',
          sm: '20px',
          xs: '14px'
        }}
      >
        {isLoadingJackpot ? (
          <>
            Syncing... <i className="fa-regular fa-clover fa-spin" />
          </>
        ) : (
          'JACKPOT'
        )}
      </StyledTypography>

      {!isLoadingJackpot && (
        <StyledOdometer
          enableScrollSpy={true}
          scrollSpyOnce={true}
          preserveValue={true}
          end={jackpotAmount}
          duration={3}
          separator=","
          decimals={2}
          decimal="."
          prefix="£"
        />
      )}
    </StyledHomeCarouselJackpotBadge>
  )
}

export default HomeCarouselJackpotBadge
